.academy-container {
    width: 100%;
    margin-top: 5px;
    padding-bottom: 40px;
  }
  .academy-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;
  }

  .academy-title{
    color: #ed1450;
    font-size: 17px;
    margin-top: 35px;
  }

  .tto{
    margin-top: 20px;
  }