.profile-deal-item-container {
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  &:last-child {
    border-bottom: unset;
  }
  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    @media screen and (max-width: 600px) {
      .right {
        margin-top: 15px;
        position: absolute;
        bottom: -5px;
        right: 0px;
      }
    }
  }

  .bottom {
    margin-top: 10px;
    .info {
      margin: auto 10px auto 0;
      color: grey;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 500px) {
    .top {
      flex-direction: column;
    }
  }
}

.white-background-button {
  color: grey;
  border: 1px solid grey;
  padding: 0px 12px;
  border-radius: 20px;
  cursor: pointer;
  width: fit-content;
  &:active {
    opacity: 0.7;
  }
  &:last-of-type {
    margin-left: 8px;
  }
}

.ant-popover-buttons,
.ant-modal-footer {
  .ant-btn:hover,
  .ant-btn:focus {
    color: #ed1450;
    border-color: #ed1450;
  }

  .ant-btn-primary {
    color: white !important;
  }
}
