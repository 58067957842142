.message {
  flex-grow: 1;
  overflow: hidden;

  &-check {
    &:hover .ant-checkbox-inner {
      border: 1px solid #ed1450;
    }

    .ant-checkbox-checked {
      &::after {
        border: 1px solid #ed1450;
      }

      .ant-checkbox-inner {
        background-color: #ed1450;
        border-color: #ed1450;
      }
    }
  }

  &-wrapper {
    width: 95%;
    max-width: 800px;
    height: 100%;
    margin: 0 auto;
    background-color: white;

    & .str-chat-channel {
      max-height: unset;
      height: 100%;
    }

    & .str-chat__main-panel {
      background-color: white;
      padding: 0 !important;
      position: relative;
    }

    .str-chat__message-simple-status,
    .str-chat__emojiselect-wrapper {
      display: none;
    }

    .rfu-file-upload-button {
      top: 50%;
      transform: translateY(-50%);

      & span {
        display: flex;
        align-items: center;
      }
    }

    .str-chat__send-button {
      flex: 0 0 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #ed1450;
      display: flex;
      align-items: center;
      justify-items: center;

      & svg {
        height: 100%;
        margin: 0 auto;
        width: 50%;

        & path {
          fill: #ffffff !important;
        }
      }

      &:focus {
        outline: none;
      }
    }

    .str-chat__date-separator {
      padding: 10px;

      & div {
        font-size: 12px;
      }
    }

    &-box {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 800px;
      max-width: 100%;
      background: #ffffff;

      .str-chat__input-flat {
        padding: 0 0;
      }
    }
    &-action {
      padding: 0 20px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        &:first-child {
          & button {
            color: #ed1450;
            padding: 0 10px;
            font-size: 16px;

            &:disabled {
              color: #999;
            }
          }
        }
      }
    }

    @media screen and (max-width: 600px) {
      .message-wrapper-action > div:first-child button {
        font-size: 12.5px;
      }

      .list-text {
        font-size: 12px;
      }
    }

    .str-chat__input-flat-wrapper {
      align-items: center;
    }

    .str-chat__input-flat .str-chat__textarea > textarea {
      background: rgb(247, 247, 247, 0.7);
      border-radius: 30px;
      padding: 12px 10px 10px 15px;
      display: block;
      margin: 0;
      box-shadow: 0 0 0 2px #00000019;
      min-height: 40px;
      font-size: 14px;
      outline: none;

      &:focus {
        outline: none;
        color: var(--black);
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media screen and (min-width: 420px) {
      .str-chat__message--me .str-chat__message-inner,
      .str-chat__message-simple--me .str-chat__message-inner {
        margin-left: 5px;
      }
    }

    @media screen and (min-width: 768px) {
      .str-chat__send-button {
        display: unset;
      }
    }

    .str-chat__avatar.str-chat__avatar--circle {
      display: none;
    }

    .messaging.str-chat .str-chat__list {
      padding: 0 10px 0 10px;
      margin-bottom: 180px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media screen and (max-width: 700px) {
      .messaging.str-chat .str-chat__list {
        margin-bottom: 210px;
      }
    }

    .str-chat__message-simple__actions__action {
      &--thread {
        display: none !important;
      }
    }

    .str-chat__message-text-inner.str-chat__message-simple-text-inner {
      padding: 5px 10px;
      font-size: 14px;
      color: #484848;
    }

    .str-chat__message {
      .str-chat__message-text-inner.str-chat__message-simple-text-inner {
        background-color: #ffbcd6;
      }
    }

    .str-chat__message--me.str-chat__message-simple--me {
      .str-chat__message-text-inner.str-chat__message-simple-text-inner {
        background-color: #fb4570;
        color: white;
      }
    }
  }
}

.actions {
  &-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;

    &-section {
      padding: 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -7px;
    }
  }
}

.msg {
  width: 100%;
  margin-top: 15px;
  padding-bottom: 40px;

  &-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
  }

  &-discussions {
  }

  &-bar-left span {
    border-radius: 10px;
    font-size: 13px;
    background-color: #14a014;
    color: white;
    padding: 0px 6px;
    margin-left: 5px;
  }

  &-bar {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-left {
      color: #585858;
      font-weight: bold;
      margin: auto 0;
      font-size: 24px;
      display: flex;
      align-items: center;

      // & span {
      //   font-weight: 400;
      //   font-size: 16px;
      //   margin-left: 5px;
      //   margin-top: 4px;
      // }
    }

    &-right {
      & > div {
        min-width: 160px;

        .ant-select {
          font-weight: 500;
          color: #ed1450;
          font-size: 14px;
          width: 100%;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid #c7c7c7;
        }
      }
    }
  }

  &-end {
    padding: 20px 0;
    border-top: 1px solid rgb(238, 238, 238);
    text-align: center;
    margin: auto 0;
    font-size: 12px;
  }
}

.instructions-box-two {
  min-height: calc(100vh - 75px);
  width: 100%;
  // padding: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.instructions-wrap {
  padding: 2px 20px;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  grid-column-gap: 15px;
}

.instructions-wrap-two {
  padding: 0px 20px;
  border-radius: 5px;
  width: 96.7%;
  margin-left: 15px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  min-height: 35px;
  grid-column-gap: 15px;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    width: 96.3%;
    margin: 0% 2%;
  }
}

.instructions-content-wrap {
  margin-bottom: 80px;
}

.instruction-title {
  font-weight: 700;
  font-size: 22px;
  margin-top: 10px;
  color: #14a014;
}

.list-text {
  margin: 7px 0px;
  color: #999999;
}

.message-box {
  margin-top: 10px;
}

.nav-bottom-btn-box {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  position: fixed;
  // left: 0%;
  bottom: 0%;
}

.nav-bottom-btn {
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -7px;
  background-color: white;
  left: 0%;
  width: 100%;
}

.completed-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .restart-box {
    display: flex;
    align-items: center;
  }

  button {
    background-color: transparent;
    border: none;
    color: #14a014;
    text-decoration: underline;
    cursor: pointer;
  }
}

.mod-btn {
  width: 47%;
  background-color: gray;
  border: 1px solid gray !important;
  color: white;
  height: 35px;
}

.mod-btn-box {
  margin-top: 20px;
}

// .ant-btn[disabled] {
//   background-color: gray;
//   color: white;
// }

.ant-btn[disabled]:hover {
  background-color: gray;
  color: white;
}

.dealer-username {
  margin-left: 4px;
}
