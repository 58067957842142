.profile-container {
  width: 100%;
  margin-top: 5px;
  padding-bottom: 40px;
  .profile-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;

    .quick-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      grid-column-gap: 10px;
      margin-bottom: 20px;

      .dashboard-btn {
        background-color: white;
        color: #ed1450;
      }

      .dashboard-btn:hover {
        color: white;
        background-color: #ed1450;
      }

      @media screen and (max-width: 600px) {
        justify-content: flex-start;
      }
    }

    .user-info {
      text-align: left;
      font-weight: 500;

      .username {
        color: #14a014;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        cursor: default;
      }

      .full_name {
        font-size: 13px;
        color: #00000070;

        cursor: pointer;
      }

      .bio_text {
        font-size: 13px;
        color: #00000070;
        cursor: pointer;
      }

      .name-box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
      }

      .views-text {
        font-size: 13px;
        color: #00000070;
        cursor: pointer;
        float: right;
      }

      .summary_text {
        color: rgb(20, 160, 20);
        font-weight: 600;
        line-height: 0px;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
      }

      .web_site {
        font-size: 13px;
        color: #00000070;
        text-decoration: underline;
        cursor: pointer;
      }

      .user-id {
        font-size: 12px;
        color: grey;
      }

      .rate {
        font-size: 13px;
        color: #5e5e5e;
        margin-top: 5px;

        span {
          color: rgb(20, 160, 20);
          cursor: pointer;
          &:active {
            opacity: 0.7;
          }
        }
      }

      .like,
      .dislike {
        cursor: default;
      }

      .connect {
        font-size: 12px;
        color: #5e5e5e;
        margin-top: 5px;

        span {
          color: rgb(20, 160, 20);
          cursor: pointer;
          &:active {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .ant-divider-inner-text {
    color: #ed1450;
  }

  .more-items {
    color: grey;
    margin: 10px 0 70px 0;
    text-align: center;
    cursor: pointer;
    &:active {
      opacity: 0.7;
    }
  }

  .no-item {
    color: grey;
    padding: 20px 0 60px 0;
    text-align: center;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: grey;
}

.ant-tabs {
  color: grey;
}

.ant-tabs-tab-btn:active,
.ant-breadcrumb a:hover {
  color: #ed1450;
}

.ant-tabs-ink-bar {
  background: #ed1450;
}

.ant-breadcrumb {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}

.notice-text {
  font-size: 14px;
}

.ant-divider-horizontal {
  margin: 8px 0;
}

.tag-box{
  display: flex;
  grid-column-gap: 10px;
  flex-wrap: wrap;
  grid-row-gap: 7px;

}

.tag-itself{
  width: fit-content;
  padding: 1px 5px;
  text-decoration: none;
  background-color: #a9fca9;
  color: #14a014;
  border: 1px solid #14a014;
  border-radius: 2px;
}