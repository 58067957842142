.navbar-container {
  width: 100%;
  border-bottom: 1px solid #cecece;
  background-color: white;
  // position: fixed;
  // z-index: 10;
  .navbar-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    .row-one {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 74px;
      .left {
        margin: auto 0;
        color: #ed1450;
        cursor: pointer;
        font-weight: 500;
        display: flex;

        .app-name {
          font-size: 20px;
          font-weight: 700;
          color: #ed1450;
          &:active {
            opacity: 0.7;
          }
          .bottom {
            font-size: 12px;
            font-weight: 400;
            color: #575757;
            margin-top: -2px;
            line-height: 15px;
          }

          .nav-link {
            padding-top: 2px;
            display: flex;
            align-items: center;
            grid-column-gap: 5px;
            width: 100%;
            line-height: 25px;
          }

          .logo {
            width: 35px;
            height: auto;
            object-fit: cover;
          }
        }

        .nav {
          margin: auto 0;
          font-size: 16px;
          &:active {
            opacity: 0.7;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .balance {
          margin: auto 0;
          margin-right: 10px;
          color: #ed1450;
          padding: 0px 10px;
          border-radius: 4px;
          font-weight: 600;
          font-size: 18px;
        }

        .notifications {
          margin-right: 15px;
        }

        .name {
          margin: auto 0;
          color: #ed1450;
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;

          &:active {
            opacity: 0.7;
          }
        }
      }

      .ant-btn {
        margin-top: auto;
        margin-bottom: auto;
        padding: 0 15px;
        margin-right: 5px;

        &:active {
          opacity: 0.7;
        }
      }

      .ant-btn-primary {
        background: #ed1450;
        border-color: #ed1450;
      }

      .ant-btn-link {
        color: #ed1450;
        font-weight: 500;
      }
    }

    .row-two {
      padding-left: 10px;

      .top {
        font-size: 25px;
        font-weight: 700;
        color: #ed1450;
      }

      .bottom {
        margin-top: -5px;
        font-weight: 500;
        color: rgb(87, 87, 87);
      }
    }

    .row-three {
      padding-left: 10px;
      margin-top: 10px;
      font-size: 11px;
      font-weight: 500;
      color: rgb(87, 87, 87);
    }
  }

  .anticon {
    margin-right: 2px;
    transform: translateY(1px);
  }
}

.ant-dropdown-menu {
  min-width: 100px;
  text-align: left;
}

.ant-dropdown-menu-item {
  font-size: 14px;
  padding: 5px;
  padding-left: 15px;
  font-weight: 500;
  color: #575757;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}


.nav-top-bar{
  background-color: #14a014;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 2%;
  margin: 0px auto;
  margin-top: 10px;
  border-radius: 2px;
  grid-column-gap: 20px;
  

  @media screen and (max-width: 600px) {
    padding: 5px 0%;
  }
}

.nav-top-text{
  color: #fff;
  font-size: 14px;
  // line-height: 0px;
  margin: 0px;
  // display: flex;
  // align-items: center;
  grid-column-gap: 7px;


  span{
    font-weight: 700;
  }

  @media screen and (max-width: 600px) {
    line-height: 17px;
    font-size: 12px;
  }
}

.nav-top-arrow{
  padding-top: 4px !important;
}

@media screen and (max-width: 600px) {
  .nav-top-bar{
    width: 95%;
    padding: 8px 4%;
    align-items: flex-start;
  }

  .nav-hide{
    display: none;
  }

  .nav-top-text{
    font-size: 14px;
  }
}