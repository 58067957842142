.form-container {
  width: 100%;
  // margin-top: 64px;
  .form-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;

    .form {
      margin: auto;
      align-items: center;
      max-width: 600px;
      padding: 10px 3vw;
      border-radius: 4px;
      margin-bottom: 30px;
      margin-top: 10px;

      .image-wrapper {
        text-align: center;
      }

      .title {
        padding-top: 20px;
        font-weight: 700;
        font-size: 25px;
        color: rgb(88, 88, 88);
        margin-bottom: 15px;
      }

      .app-name {
        font-size: 20px;
        font-weight: 700;
        color: #ed1450;
        &:active {
          opacity: 0.7;
        }
        .bottom {
          font-size: 12px;
          font-weight: normal;
          color: #575757;
          margin-top: -5px;
        }
      }

      .desc {
        margin-bottom: 20px;

        &.custom {
          margin-bottom: 4px;
          margin-top: 15px;
        }

        .desc-text {
          font-size: 12px;
          color: rgb(87, 87, 87);
          margin: 3px 0%;
        }

        .desc-link {
          color: #ed1450;
          font-weight: 500;
          text-decoration: underline;
          cursor: pointer;

          &:active {
            opacity: 0.7;
          }
        }
      }

      .desc-text-2 {
        font-size: 12px;
        color: rgb(87, 87, 87);
        margin-top: 15px;
        margin-bottom: 0px;
      }

      .info-link {
        font-size: 12px;
        margin: 0px 0%;
        color: #ed1450;
        text-decoration: underline;
        font-weight: 500;
      }

      .referral {
        color: #ed1450;
        cursor: pointer;
        margin-bottom: 5px;
        font-size: 12px;

        &:active {
          opacity: 0.7;
        }
      }

      .forgot {
        text-align: end;
        margin-top: -20px;
        color: #ed1450;
        font-size: 12px;
      }

      .verify-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .ant-input {
          width: 14%;
        }

        .ant-form-item {
          margin-bottom: 10px;
        }

        .ant-input:hover {
          border-color: #d9d9d9;
        }

        .ant-input:focus,
        .ant-input-focused {
          border-color: #d9d9d9;
          box-shadow: 0 0 0 2px #d9d9d9;
        }
      }

      .ant-btn:active,
      .ant-btn:hover,
      .ant-btn:focus {
        border-color: #ed1450;
        // color: #ed1450;
      }
    }

    .already {
      margin-top: 20px;
      font-size: 12px;
    }
  }
}

a {
  color: #ed1450;

  &:hover {
    color: #ed1450;
  }

  &:active {
    opacity: 0.7;
  }
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-number:hover {
  border-color: #eec4d0;
  box-shadow: 0 0 0 2px #eec4d0;
}

// .ant-input:hover,
// .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
//   box-shadow: 0 0 0 2px #eec4d0;
// }

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #f387a6;
}

.ant-input:hover {
  border-color: #f387a6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.form-control {
  width: 100% !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #eec4d0;
}

.ant-btn {
  &.ant-btn-primary {
    border-color: #ed1450;
    background: #ed1450;
    &:hover {
      color: white;
    }
  }
  &.login-form-button {
    width: 100%;
    margin-top: 20px;
  }
  &.short {
    width: unset;
    background: white;
    color: #ed1450;
    &:hover {
      border-color: #ed1450;
    }
  }
}

.css-yk16xz-control {
  border-radius: 2px !important;
  border: 1px solid #eec4d0;
  border-color: #d9d9d9 !important;
}

.css-1pahdxg-control {
  box-shadow: 0 0 0 2px #eec4d0 !important;
  border: 1px solid #f387a6;
  border-color: #f387a6 !important;
  &:hover {
    border-color: #f387a6 !important;
  }
}

.css-1wa3eu0-placeholder {
  color: #cacaca !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ed1450;
  border-color: #ed1450;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ed1450;
}

.ant-checkbox-inner {
  border: 1px solid #ed1450;
}

.ant-checkbox:hover {
  border-color: #ed1450;
}

.confirm-vendor {
  margin-bottom: 20px;
}

.vendor-text {
  font-size: 12px;
  color: rgb(87, 87, 87);
}

.form-select {
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-top: 35px;
  margin-bottom: 10px;
}
