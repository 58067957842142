.deal-item-container {
  width: 100%;
  padding: 30px 0 15px 0;
  border-bottom: 1px solid rgb(238, 238, 238);
  position: relative;
  &:last-child {
    border-bottom: unset;
  }

  .left {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    cursor: pointer;

    .avatar {
      margin-right: 6px;
      transform: translateY(4px);
    }
    &:active {
      opacity: 0.7;
    }
  }

  .source-destination {
    position: absolute;
    top: 14px;
    right: 0;
  }

  .deal-item-wrapper {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;

    .deal-item-row-one {
      margin-bottom: 20px;
      font-size: 15px;
      color: rgb(94, 94, 94);
      // font-style: italic;
      line-height: 1.5;
      font-weight: 500;
      text-transform: lowercase;
    }

    .deal-item-row-two {
      width: 100%;
      font-size: 12px;
      color: grey;
      line-height: 1.5;

      .location {
        text-decoration: underline;
      }

      .discussion {
        font-weight: 600;
        color: rgb(20, 160, 20);
        text-decoration: underline;
      }
    }

    .deal-item-row-three {
      font-size: 12px;
      color: grey;
    }

    .deal-item-row-four {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;

      .like-dislike {
        font-size: 16px;
        font-weight: 500;
        margin: auto 0;

        .like,
        .dislike,
        .score {
          cursor: pointer;

          &:active {
            opacity: 0.6;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: row;

        .white-background-button {
          margin-right: 8px;
        }
      }
    }
  }

  .status {
    font-weight: bold;
    color: #14a014;
    font-size: 11px;
    line-height: 0%;
    margin-top: 11px;
    margin-bottom: 6px;
  }
}

.green-button {
  background: rgb(20, 160, 20);
  color: white;
  padding: 2px 15px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 2px;
  &.disabled {
    background: #dddddd;
    cursor: not-allowed;
    &:active {
      opacity: 1;
    }
  }
  &:active {
    opacity: 0.8;
  }
}

.username-wrap {
  display: flex;
  flex-direction: column;
}

.username-green-text {
  color: rgb(20, 160, 20);
  font-size: 17px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;

  &:active {
    opacity: 0.7;
  }

  :first-child {
    // font-size: 13px;
    // color: grey;
  }

  .username-wrapper {
    display: flex;
    align-items: center;
  }

  .full_name {
    font-size: 12px;
    color: #00000070;
    font-weight: 500;
  }
}

.username-red {
  color: #ed1450;
  font-size: 16px;
  font-weight: 500;
}

.source-destination {
  width: fit-content;
  height: 22px;
  font-size: 12px;
  color: white;
  background-color: #a9fca9;
  padding: 2px 10px;
  border-radius: 10px;
  color: #14a014;
}

.source-destination-rate {
  width: auto;
  font-size: 12px;
  color: #5e5e5e;
  padding: 2px 5px;
  line-height: 18px;
  margin-top: 10px;
}

.ant-modal-title {
  color: #000000d9;
  font-weight: 600;
}

.discussion-tc-notice {
  width: auto;
  height: 22px;
  font-size: 12px;
  color: #5e5e5e;
  margin-left: 10px;
}

.bold {
  font-weight: 600;
}

.rate-text {
  margin-left: 5px;
}

.like-dislike {
  font-size: 15px;
  margin-top: 5px;
  &.no-margin-top {
    margin-top: unset;
    margin: auto 0;
  }
  .like {
    color: rgb(20, 160, 20);
    margin-right: 18px;
    font-weight: bold;

    .anticon {
      transform: translateY(-1px);
    }
  }

  .dislike {
    color: #ed1450;
    margin-right: 18px;
    font-weight: bold;
    &.add-margin-right {
      margin-right: 25px;
    }
    &.no-margin-right {
      margin-right: 0px;
    }
    .anticon {
      transform: translateY(1px);
    }
  }

  .score {
    color: grey;
    font-weight: bold;
  }

  .anticon {
    margin-right: unset;
  }
}

.score-green {
  font-size: 12px;
  margin-right: 2px;
  margin-top: 10px;
  color: rgb(20, 160, 20);
}

.ant-modal-confirm-btns {
  .ant-btn:hover,
  .ant-btn:focus {
    color: #ed1450;
    border-color: #ed1450;
  }

  .ant-btn-primary {
    color: white !important;
  }
}

.receipt-tag {
  color: #14a014;
  font-weight: 600;
}
