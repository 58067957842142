.cont-box {
  width: 100%;
}

.review-btn-box {
  display: flex;
  justify-content: space-between;
}

.input {
  width: 100%;
  margin: 20px 0px;
  border: 1px solid #fb4570;
  padding: 15px;
}

.review-title {
  font-size: 22px;
  color: #585858;
  font-weight: bold;
  margin-bottom: 25px;
}

.review-wrap {
  padding: 0px 20px;
  border-radius: 5px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  margin-top: 5px;
  display: flex;
  // align-items: center;
  min-height: 35px;
  grid-column-gap: 15px;
  box-sizing: border-box;
  // position: absolute;
  margin-top: 40px;
}

.parent {
  position: relative;
}

@media screen and (max-width: 800px) {
  .parent{
    width: 90%;
    margin: 0 auto;
  }
  
}
