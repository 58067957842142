.new-deal-container {
  width: 100%;
  margin-top: 5px;

  .new-deal-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;

    .top-bar {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;

      .left,
      .right {
        color: #ed1450;
        font-weight: 500;
        margin: auto 0;
        font-size: 17px;
      }
    }

    .deal-form-container {
      margin: auto;
      align-items: center;
      max-width: 800px;
      width: 100%;
      border-radius: 4px;
      margin-bottom: 30px;
      margin-top: 10px;

      .form-row {
        position: relative;
        .tooltip-container {
          position: absolute;
          top: 39px;
          right: 0;
          &.origin {
            top: 0px;
          }
        }
      }

      .ant-alert-message{
        color: #5e5e5e !important;
        font-weight: 600;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ed1450;
        border-color: #ed1450;
    }

      .ant-form-item-label > label {
        font-size: 14px;
        color: #5e5e5e;
        font-weight: 500;
      }

      .ant-alert {
        &-message {
          color: #ed1450;
        }
      }

      .ant-radio {
        font-size: 14px;
        font-weight: 500;
        color: #ed1450;

        &-wrapper {
          &:not(:last-child) {
            margin-right: 20px;
          }
        }

        &-inner {
          border: 1.5px solid #ed1450;

          &::after {
            background-color: #ed1450;
          }
        }
      }

      .ant-input,
      .ant-input-affix-wrapper,
      .ant-select-selector,
      .ant-input-number {
        border: 1.5px solid #ed1450;
      }

      .ant-input-affix-wrapper .ant-input {
        border: none;

        &:focus {
          box-shadow: none;
        }
      }

      .ant-select-selection-placeholder,
      .ant-input-selection-placeholder {
        color: #ed1450;
      }

      .ant-form-item-required {
        &::before {
          content: '';
        }
        &::after {
          display: inline-block;
          margin-left: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }

      .question-tooltip {
        height: 20px;
        width: 20px;
        display: inline-block;
        color: #ed1450;
        text-align: center;
        border-radius: 50%;
        border: 1.5px solid #ed1450;
        font-weight: 600;
        font-size: 12px;
      }
    }

    .ant-form-item {
      margin-bottom: 15px;
    }

    .form-row-sec{
      display: flex;
      grid-column-gap: 10px;
    }
  }
}
