.txmodal {
  & .ant-modal-footer {
    display: none;
  }

  &-body {
    padding: 10px;

    & h4 {
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 600;
    }

    & h3 {
      font-size: 22px;
      margin-bottom: 0;
      text-align: center;
      font-weight: 400;

      & + p {
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        // margin-bottom: 30px;
      }
    }

    .tx-header{
      display: flex;
      justify-content: space-between;
      align-items: center;

      p{
        margin-bottom: 30px;
      }
    }

    & h2 {
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      color: #14a014;
      margin-bottom: 0;

      & + p {
        text-align: center;
        // font-weight: 600;
        font-size: 16px;
        // margin-bottom: 50px;
      }
    }
  }

  &-hash {
    margin-bottom: 0;
    font-weight: 600;

    & + p {
      width: 300px;
      max-width: 90%;
      color: #14a014;
      margin-bottom: 30px;
    }
  }

  &-link {
    display: flex;
    align-items: center;
  }
}
