.profile-review-item-container {
  padding: 15px 0;
  border-bottom: 1px solid #eeeeee;
  // display: flex;
  // justify-content: space-between;
  // width: 100%;

  &:last-child {
    border-bottom: unset;
  }
  .row-one {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    grid-column-gap: 15px;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .date {
      font-size: 12px;
      color: grey;
      margin: auto 0;
      padding-left: 3px;
    }

    .box {
      width: 33%;
    }

    .box-two {
      height: fit-content;
      width: 33%;
    }

    .box-three {
      height: fit-content;
      width: 33%;
      display: flex;
      justify-content: flex-end;
    }

    @media screen and (max-width: 550px) {
      grid-column-gap: 10px;
    }

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
      justify-content: unset;

      .box-two {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
      }

      .box-three {
        width: 100%;
        margin-top: 8px;
      }
    }
  }
}

.status {
  font-weight: 600;
  font-size: 12px;
}

.text-green {
  color: #14a014;
  font-size: 14px;
}

.text-red {
  color: #ed1450;
  font-size: 14px;
}

.like-text {
  color: #14a014;
  font-size: 14px;
}

.mobile-white-background {
  color: grey;
  border: 1px solid grey;
  padding: 0px 12px;
  border-radius: 20px;
  cursor: pointer;
  width: fit-content;
  &:active {
    opacity: 0.7;
  }
  &:last-of-type {
    // margin-left: 8px;
  }

  display: none;
}

.profile-white-background {
  color: grey;
  border: 1px solid grey;
  padding: 0px 12px;
  border-radius: 20px;
  cursor: pointer;
  width: fit-content;
  &:active {
    opacity: 0.7;
  }
  &:last-of-type {
    margin-left: 8px;
  }
}

.source-badge {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #9797976e;
  width: 123px;
  color: white;
  padding: 1px 7px;
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.username-reviewitem-text {
  color: rgb(20, 160, 20);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 4px;

  &:active {
    opacity: 0.7;
  }

  @media screen and (max-width: 550px) {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .profile-white-background {
    font-size: 11px;
    width: 66px !important;
    padding: 0px 0px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-of-type {
      margin-left: 2px;
    }
  }

  .text-green {
    font-size: 12px;
  }

  .text-red {
    font-size: 12px;
  }

  .date {
    font-size: 11px;
  }
}
