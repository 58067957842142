body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-tag {
  line-height: unset !important;
  padding: 0px 3.5px !important;
}

.ant-modal-header {
  border-bottom: 0px !important;
  padding-top: 25px !important;
  padding-bottom: 0% !important;
}

.ant-modal-footer {
  border-top: 0px !important;
  padding-bottom: 26px !important;
}

.ant-modal-close-x {
  display: none !important;
}

.ant-modal-content {
  /* padding: 10px; */
}

.notification-text {
  width: 47%;
  line-height: 9px;
}

.text-margin {
  margin: 0px 0px 0px 0px;
}

.text-body-bg {
  background-color: #a5a5a5;
}

.chat-card {
  background-color: #a5a5a5;
}


.progress {
  background:  #a5a5a5;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  height: 10px;
  width: 100%;
}

.progress-value {
  animation: load 3s normal forwards;
  border-radius: 100px;
  background:  rgb(20, 160, 20);;
  height: 10px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
