.notification {
  width: 100%;
  margin-top: 5px;

  &-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;

    & h1 {
      font-weight: 700;
      color: #585858;
      font-size: 24px;
      margin: auto 0;
      margin-bottom: 30px;
    }
  }

  &-card {
    border: 1px solid #ed1450;
    background-color: #fff2f6;
    padding: 10px 15px;
    margin-bottom: 20px;
    cursor: pointer;

    &-disabled {
      border-color: #dddddd;
      background-color: #ffffff;
    }

    & p,
    & .username-green {
      font-size: 14px !important;
      // @media screen and (max-width: 48em) {
      // }
    }

    &-btn {
      padding: 1px 10px;
      cursor: pointer;
      font-size: 14px;
      border-radius: 2px;
      border: 1px solid #ffffff;

      &-pink {
        background: #ed1450;
        color: #ffffff;
        border-color: #ed1450;
      }

      &-out {
        background: #ffffff;
        color: #ed1450;
        border-color: #ed1450;
      }
    }
  }

  &-link {
    font-weight: 700;
    color: #14a014;

    &:hover {
      color: #14a014;
    }
  }

  &-pink {
    font-weight: 700;
    color: #ed1450;
  }

  &- &-disabled {
    background: #dddddd;
    border-color: #dddddd;
    cursor: not-allowed;

    &-rej {
      color: #dddddd;
      border-color: #dddddd;
      cursor: not-allowed;
    }
  }
}
