.walletModal {
  & .ant-modal-footer {
    display: none;
  }

  & .ant-form-item-label > label {
    height: unset;
  }

  &-box {
    display: flex;
    align-items: flex-start;
    padding: 0px 5px;

    &-left {
      flex: 1;

      & h3 {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  &-recieve {
    &-icon {
      transform: rotate(180deg);
    }

    & p {
      font-size: 12px;
      font-weight: 500;
      color: #999;
      margin-bottom: 10px;

      &.walletModal-address {
        color: #14a014;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 20px;
        word-wrap: break-word;

        @media only screen and (max-width: 540px) {
          width: 250px;
        }
      }
    }
  }

  &-send {
    & h3 {
      margin-top: 0;
    }

    & h4 {
      margin-bottom: 20px;
    }

    &ant-input {
      font-size: 16px;
    }
  }

  // & .ant-alert-message {
  //   color: #ed1450;
  //   font-size: 13px;
  //   line-height: 15px;
  // }

  &-p {
    word-wrap: break-word;

    @media only screen and (max-width: 540px) {
      width: 200px;
    }
  }
}

.address-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 20px;
  border: 1px solid #99999955;
  color: #fb4570;
  font-size: 14px;
  padding: 4px 8px;
  margin: 15px 0px 15px 0px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.copy-text {
  background-color: #fffbe6;
  padding: 4px 10px;
  color: #99999955;
  border-radius: 20px;
  width: fit-content;
  cursor: pointer;
}

.address-textt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 90px);
  margin: 0% !important;
  color: #fb4570 !important;
  font-size: 13px !important;
}

.alert-textt {
  font-size: 14px !important;
  margin-bottom: 6px !important;

  span {
    font-weight: 700;
  }
}
