.dealmodal {
  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-confirm .ant-modal-body {
    padding: 18px;
  }

  .lily-username-green {
    color: #14a014;
    font-size: 17px;
    line-height: 0px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 12px;
  }

  .deal-details{
    margin-top: 5px;
  }

  .ant-modal-footer,
  .ant-modal-close {
    display: none;
  }

  @media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-label {
      flex: 0 0 37.5%;
    }

    .ant-form .ant-form-item .ant-form-item-control {
      flex: 0 0 50%;
    }
  }

  .terms-box{
    display: flex;
    grid-column-gap: 13px;
    margin-top: 20px;
  }

  .terms-text{
    font-size: 13px;
    line-height: 17px;
  }
}
