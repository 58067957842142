.banner-bg {
  background-image: url("../../assets/banner.png");
  width: 100%;
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 27px 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner-title {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  margin-top: 30px;
}

.banner-text {
  width: 100%;
  max-width: 450px;
  line-height: 8px;
  text-align: center;
  font-size: 17px;
  font-weight: 300;
  color: #fff;
}

.banner-btn {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  width: 150px;
  height: 50px;
  color: #fff;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .banner-title {
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 25px;
    font-weight: 700;
  }

  .banner-btn {
    width: fit-content;
    margin-top: 20px;
  }
}

@media screen and (max-width: 500px) {
  .banner-text {
    font-size: 15px;
    line-height: 5px;

  }
}
