.home {
  width: 100%;
  margin-top: 5px;

  &-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;

    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      p {
        .tag-wrap {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }

    .home-card-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      grid-gap: 20px;
    }

    .title-bar {
      font-size: 24px;
      color: #585858;
      font-weight: bold;
    }

    .icon-text{
      display: flex;
      align-items: center;
      padding-bottom: 15px;
    }

    .home-card {
      border: 1px solid #ed1450;
      background-color: #fff;
      padding: 10px 15px;
      cursor: pointer;
      width: 48%;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ed1450;
      flex-direction: column;
    }

    @media screen and (max-width: 532px) {
      .home-card {
        width: 100%;
      }
    }
  }
}
