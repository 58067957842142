@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  height: 100%;
  font-family: "Poppins", sans-serif;
  width: 100% !important;
}

.username-green-n {
  color: #14a014;
  font-size: 17px;
  line-height: 17px;
  font-weight: bold;
  cursor: pointer;
}

.App {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.ant-btn-primary {
  background: #ed1450;
}

.ant-layout {
  background: unset !important;
  height: 100%;
}

.layout {
  height: 100vh;
  display: flex;
  flex-flow: column;

  &-body {
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-flow: column;
  }
}

.ant-input {
  line-height: 2.1;
}

.ant-tooltip-inner {
  font-size: 12px;
}

::selection {
  background: black; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: black; /* Gecko Browsers */
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffe3eb;
  // border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  background: #ed145060;

  &:hover {
    background: #ed145090;
  }
}

*,
*::after,
*::before {
  font-family: "Poppins", sans-serif !important;
}

.ant-upload-list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ant-popconfirm {
  z-index: 100;
}

.cancel-text {
  color: white;
}

.list-text-n {
  width: 40%;
  margin-bottom: 5px !important;
}

.list-text-d {
  width: 60%;
  margin-bottom: 5px !important;
}

// .ant-radio-button-wrapper{
//   width: 50%;
// }

// .ant-radio-group{
//   width: 100%;
// }

// .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
//   color: #ed1450;
//   border-color: #ed1450;
// }

.ant-switch-checked {
  background-color: #14a014;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 40px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #14a014;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
