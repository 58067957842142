.footer-container {
  width: 100%;
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid #cecece;

  .footer-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding-bottom: 20px;

    .row-two {
      padding-left: 10px;

      .top {
        font-size: 19px;
        font-weight: 700;
        color: #ed1450;

        .nav-link {
          display: flex;
          align-items: center;
          grid-column-gap: 5px;
          width: 100%;
        }

        .logo {
          width: 30px;
          height: auto;
          object-fit: cover;
        }
      }

      .bottom {
        margin-top: -5px;
        font-weight: 500;
        color: rgb(87, 87, 87);
        font-size: 12px;
      }
    }

    .row-three {
      padding-left: 10px;
      margin-top: 10px;
      font-size: 11px;
      font-weight: 500;
      color: rgb(87, 87, 87);

      .right {
        text-align: right;
      }
    }
  }
  .anticon {
    margin-right: 2px;
    transform: translateY(1px);
  }
}

.ant-dropdown-menu {
  min-width: 100px;
  text-align: left;
}
