.privacy-container {
  width: 100%;
  margin-top: 5px;
  padding-bottom: 40px;
}
.privacy-wrapper {
  width: 95%;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 10px;
}

.privacy-title {
  margin-top: 15px;
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

.privacy-sub-title {
  margin-top: 15px;
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.privacy-text {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
}

.privacy-list {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin-top: 10px;
}

.privacy-list-bold {
    font-size: 15px;
    font-weight: 600;
    color: #000;
    margin-top: 3px;
  }
