.wallet {
  width: 100%;
  margin-top: 5px;
  padding-bottom: 40px;
  position: relative;

  &-alert {
    & .ant-alert-message {
      color: #14a014;
      font-size: 13px;
      line-height: 15px;
    }

    &-err {
      & .ant-alert-message {
        color: #ed1450;
      }
    }
  }

  .ant-divider-inner-text {
    color: #ed1450;
  }

  .ant-table-row {
    cursor: pointer;
  }

  &-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;
  }

  &-p {
    font-size: 14px;
    color: #999;
    margin: 0;
  }

  &-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    & div {
      &:first-child {
        display: flex;
        align-items: center;
        // gap: 5px;

        & div {
          flex: 0 0 24px;
          height: 24px;
        }
      }
    }
  }

  &-coin {
    width: 100%;
    margin: 20px 0px 30px 0px;
    margin-bottom: 40px;

    & h2 {
      margin: 0;
      font-weight: 700;
      font-size: 30px;
      cursor: default;
    }

    & .header-color-positive {
      color: #14a014;
    }

    & .header-color-negative {
      color: #ed1450;
    }

    &-btn {
      display: flex;
      // gap: 30px;
      align-items: center;
      margin-bottom: 15px;

      & button:first-child {
        margin-right: 15px;
      }

      &-1 {
        background-color: #9995;
        border-color: #999;
        color: #999;

        &:hover,
        &:focus {
          background-color: #9995;
          border-color: #999;
          color: #999;
        }
      }

      & .ant-btn-primary {
        background-color: #14a014;
        border-color: #14a014;
      }
    }
  }

  &-table {
    & .ant-table-thead {
      & > tr {
        & > th {
          padding-bottom: 10px !important;
          background: #fff;
          border: unset;
          padding: unset;
          font-weight: 400;
          color: #999;
          font-size: 14px;
          width: 33%;

          &::before {
            display: none;
          }

          &:nth-child(2) {
            text-align: center;
          }

          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }
    & .ant-table-tbody {
      & > tr {
        &.ant-table-row:hover > td {
          background: #fff;
        }

        & > td {
          height: 70px;
          background: #fff;
          border: unset;
          padding: unset;
          vertical-align: top;
          font-weight: 500;
          // font-size: 10px;
          // color: #999;

          &:nth-child(2) {
            text-align: center;
          }
        }
      }
    }

    &-transaction {
      display: flex;
      align-items: flex-start;
    }
  }
}

.coin-box {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
}

.coin-img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 50px;
  // padding-left: 15px;
}

.tab-box {
  display: flex;
  width: 100%;
  border-radius: 10px;
}

.coin-tab {
  width: 50%;
  height: 50px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10px;
  cursor: pointer;
}

.default-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  // margin-bottom: 20px;
}

.default-cont {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.default-coin-img {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 50px;
  // margin-top: 100px;
}

.default-content {
  display: flex;
  grid-column-gap: 15px;
  align-items: baseline;
}

.default-coin {
  font-size: 22px;
  color: #000;
  font-weight: 600;
  line-height: 0px;
  // margin-bottom: 10px;
}

.default-coin-text {
  font-size: 14px;
  color: #00000070;
  line-height: 8px;
  font-weight: 500;
}

.default-switcht {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 8px;
}

.switch-text {
  font-size: 14px;
  line-height: 0px;
  color: #00000060;
  margin: 0px !important;
}

.switch-text-box {
  display: flex;
  grid-column-gap: 10px;
  align-items: center;
}

.wallet-bal-usd {
  font-size: 24px;
  color: #999;
  font-weight: 500;
}

.wallet-bal-bon {
  font-size: 18px;
  color: #14a014;
  font-weight: 600;
}

.wallet-bal-bon-usd {
  font-size: 18px;
  color: #999;
  font-weight: 500;
}

.wallet-switch {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.tlide {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .coin-tab {
    height: 40px;
    font-size: 13px;
  }

  .coin-img,
  .default-coin-img {
    width: 18px;
    height: 18px;
  }

  .default-coin {
    font-size: 18px;
  }

  .switch-text {
    font-size: 13px;
  }

  .notice-text {
    font-size: 13px;
  }

  .default-content {
    grid-column-gap: 10px;
  }

  .wallet-bal-bon-usd {
    font-size: 15px;
  }

  .wallet-bal-usd {
    font-size: 20px;
  }
}

.loader-box {
  position: absolute;
  width: 100%;
  max-width: 780px;
  height: calc(70vh - 70px);
  // background-color: #00000099;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  // filter: blur(5px);

  background-color: rgba(255, 255, 255, 0.4);

  -webkit-backdrop-filter: blur(5px);

  backdrop-filter: blur(5px);
}

@media screen and (max-width: 600px) {
  .loader-box {
    max-width: 90%;
    height: calc(80vh - 70px);
  }
}
