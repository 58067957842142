.deal-page-container {
  width: 100%;
  margin-top: 5px;
  padding-bottom: 40px;

  .deal-page-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;

    .user-info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        width: 100%;

        .avatar {
          margin-right: 6px;
          transform: translateY(4px);
        }
        &:active {
          opacity: 0.7;
        }
      }
      .like-dislike {
        margin-top: unset;
      }
    }

    .tag-parent-x {
      width: 100%;
    }

    .tag-box {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .deal-info {
      margin-top: 15px;
      .source-destination {
        width: auto;
        margin-bottom: 10px;
      }

      .deal-item-wrapper {
        display: block;
        width: 100%;
        margin: 0 auto;
        margin-top: 20px;

        .deal-item-row-one {
          margin-bottom: 16px;
          margin-top: 13px;
          font-size: 15px;
          color: rgb(94, 94, 94);
          // font-style: italic;
          line-height: 1.5;
          text-transform: lowercase;
          font-weight: 500;
        }

        .deal-item-row-two {
          width: 100%;
          font-size: 12px;
          color: grey;
          line-height: 1.5;
          margin-bottom: 20px;
          text-transform: lowercase;

          .discussion {
            font-weight: 600;
            color: rgb(20, 160, 20);
            text-decoration: underline;
          }
        }

        .deal-item-row-three {
          font-size: 12px;
          color: grey;

          .location {
            text-decoration: underline;
          }
        }

        .deal-item-row-four {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 15px;
        }
      }
    }
  }
}

.username-green {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0px;
  color: rgb(20, 160, 20);
  font-size: 17px;
  line-height: 17px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 12px;

  &:active {
    opacity: 0.7;
  }
}

.full_name {
  font-size: 12px;
  color: #00000070;
  font-weight: 500;
  margin-top: 1px;
  margin-bottom: 4px;
}

.status-user {
  font-size: 11px;
  line-height: 9px;
  margin-top: 6px;
}

.grey-button-nobg {
  margin: auto 25px auto 0;
  color: grey;
  font-weight: 500;
  cursor: pointer;
  &.no-margin-right {
    margin: auto 0;
  }
  &:active {
    opacity: 0.7;
  }
}
