.discussion-item-container {
  padding: 15px 10px 8px 10px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
  &:last-child {
    border-bottom: unset;
  }
  &:active {
    opacity: 0.7;
  }

  &:hover {
    background-color: #14a01407;
    transition: all 0.7s;
  }

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .username-box {
    display: flex;
    grid-column-gap: 15px;
  }

  .top-item {
    display: flex;
    flex-direction: column;
  }

  .dispute-top-item {
    // display: flex;
    // grid-column-gap: 15px;
  }

  .chat-tag {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .status {
    font-size: 10px;
    font-weight: 600;
    //  margin-bottom: 10px;
  }

  .arrow-icon {
    display: none;
  }

  .item-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .bottom {
    margin-top: -3px;

    .info {
      // margin: auto 0 auto 10px;
      color: grey;
      font-size: 12px;
    }
    .with-indicator {
      color: gray !important;
      font-weight: normal !important;
      font-size: 12px !important;
    }
  }

  .tag-text {
    color: #ed1450;
    font-weight: 500;
  }

  .dispute-tag {
    height: 27px;
    display: flex;
    justify-content: center;
  }

  .username-text {
    color: #14a014;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
  }

  .with-text {
    color: gray !important;
    font-weight: normal !important;
    font-size: 14px !important;
    font-weight: 600 !important;

  }

  @media screen and (max-width: 500px) {
    .bottom {
      display: flex;
    }

    .top-item {
      flex-direction: column;
    }

    .source-destination {
      display: none;
    }

    .arrow-icon {
      display: flex;
      margin-left: 15px;
      color: #ed1450;
    }

    .username-green {
      font-size: 14px;
    }

    .username-text {
      font-size: 14px;
    }

    .view-btn {
      display: none;
    }
  }
}
