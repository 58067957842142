.parent {
  width: 100%;
  margin-top: 5px;

  .wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;
    margin-top: 15px;

    .title-bar {
      font-size: 24px;
      color: #585858;
      font-weight: bold;

      .count {
        border-radius: 20px;
        font-size: 13px;
        // padding: 1px 7px;
      }
    }
  }
}
