.contact-container {
  width: 100%;
  margin-top: 5px;
  padding-bottom: 40px;
}
.contact-wrapper {
  width: 95%;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 10px;
}


.c-desc{
    margin-top: 10px;
}

.c-list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contact-list{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-row-gap: 20px;
}

.c-title{
    font-size: 18px;
}

.c-text{
    line-height: 15px;
    text-decoration: underline;
    cursor: pointer;
}