.chatheader {
  &-title {
    margin-bottom: 10px;
    margin-top: 10px;
    color: #14a014;
    font-size: 14px;
    display: flex;
    align-items: flex-start;

    & span {
      font-size: 14px;
    }
  }

  &-main {
    padding-bottom: 10px;

    .left {
      display: flex;
      justify-content: space-between;

      .like-dislike-chat {
        font-size: 18px;

        .like {
          color: rgb(20, 160, 20);
          margin-right: 18px;
          font-weight: bold;

          .anticon {
            transform: translateY(-1px);
          }
        }

        .dislike {
          color: #ed1450;
          margin-right: 18px;
          font-weight: bold;
          &.add-margin-right {
            margin-right: 25px;
          }
          &.no-margin-right {
            margin-right: 0;
          }
          .anticon {
            transform: translateY(1px);
          }
        }

        .score {
          color: grey;
          font-weight: bold;
        }

        .anticon {
          margin-right: unset;
        }
      }

      .name {
        color: hsl(120, 78%, 35%);
        font-size: 18px;
        font-weight: bold;
        line-height: 0%;
        cursor: pointer;
        margin-top: 9px;
        margin-bottom: 5px;
      }

      .names-wrapper {
        display: flex;
        grid-column-gap: 6px;
      }

      .avatar {
        margin-right: 6px;
        transform: translateY(4px);
      }
      .status {
        font-size: 13px;
        color: grey;
        margin-top: -5px;
      }
    }

    .right {
      display: flex;
      justify-content: space-between;

      .close {
        cursor: pointer;
        color: grey;
        &:hover {
          color: #ed1450;
        }
        &:active {
          opacity: 0.7;
        }
      }
    }
  }

  .chat-summary {
    color: #00000073;
    font-weight: 500;
    font-size: 12px;
  }
}

.with-text {
  color: gray;
  font-weight: normal;
  font-size: 14px;
  font-weight: 600;
}

.info-box{
  display: flex;
  align-items: center;
  // justify-content: flex-end;
}

.source-text{
  display: flex;
  // margin-top: 12px;
  align-items: center;
  // flex: 1;
  flex-wrap: wrap;
}

.break{
  display: none;
}

@media screen and (max-width: 600px) {
  .break{
    display: block;
  }

  .source-text{
    line-height: 15px;
    font-size: 13px;
  }
}
