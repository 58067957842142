.deals-parent{
  width: 100%;
  // overflow: auto;
  // min-height: 100vh;
  // -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer */
  // scrollbar-width: none;
}

.deals-container {
  width: 100%;
  // overflow: auto;
  // min-height: 1000px;
  // margin-top: 5px;
  

  .deals-wrapper {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
    background-color: white;
    padding: 10px;
    margin-top: 25px;

    .switch-bar {
      border-bottom: 1px solid rgb(238, 238, 238);
      padding-bottom: 20px;

      .top-bar {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left {
          color: #585858;
          font-weight: bold;
          margin: auto 0;
          font-size: 24px;
        }

        .right {
          .filter-by {
            min-width: 160px;

            .ant-select {
              font-weight: 500;
              color: #ed1450;
              font-size: 14px;
              width: 100%;
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
              border: 1px solid #c7c7c7;
            }
          }
        }
      }

      .instrument-select {
        display: flex;
        flex-direction: row;
        margin: auto 0;
        margin-top: 15px;

        .ant-select {
          font-weight: 500;
          color: #ed1450;
          font-size: 16px;
          width: 48%;
        }

        .select-box {
          width: 50%;
        }

        .pair {
          color: #575757;
          font-size: 14px;
          margin: 0%;
          padding-bottom: 7px;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 2px solid #ed1450;
        }

        .arrows {
          margin: 30px 8px 0px 8px;
          color: #ed1450;
          width: 4%;
          text-align: center;
        }

        .ant-select-arrow {
          color: #ed1450;
        }

        .ant-select-selection {
          height: 20px;
        }
      }
    }

    .deals-list {
      .filter-text {
        font-size: 13px;
        color: #3b3b3beb;
        padding: 10px 0;
        font-weight: 500;
        border-bottom: 1px solid #eeeeee;
        text-align: right;
      }
    }

    .load-more {
      min-height: 90px;
      padding-bottom: 20px;
      border-top: 1px solid rgb(238, 238, 238);
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .results-count {
        margin: auto 0;
        font-size: 12px;
      }

      .ant-btn:active,
      .ant-btn:hover,
      .ant-btn:focus {
        border-color: #ed1450;
      }
    }

    .list-end {
      padding: 20px 0;
      border-top: 1px solid rgb(238, 238, 238);
      text-align: center;
      margin: auto 0;
      font-size: 12px;
    }
  }
  .ant-divider-horizontal.ant-divider-with-text {
    margin: 15px 0 15px 0;
  }
}

.no-result {
  display: block; /* Fallback for older browsers */
  display: -webkit-box; /* Older Safari versions */
  display: -webkit-flex; /* Safari 6.1+ */
  display: -ms-flexbox; /* IE 10 */
  min-height: 300px;

  &.wrong {
    margin-top: 100px;
  }
  .svg-container {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-around;
    // display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    // display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    // display: -ms-flexbox; /* TWEENER - IE 10 */
    // display: -webkit-flex; /* NEW - Chrome */
    // display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */

    // -webkit-box-orient: horizontal; /* OLD - iOS 6-, Safari 3.1-6 */
    // -moz-box-orient: horizontal; /* OLD - Firefox 19- (buggy but mostly works) */
    // -webkit-box-direction: normal; /* OLD - iOS 6-, Safari 3.1-6 */
    // -moz-box-direction: normal; /* OLD - Firefox 19- (buggy but mostly works) */
    // -webkit-flex-direction: row; /* NEW - Chrome */
    // -ms-flex-direction: row; /* TWEENER - IE 10 */
    // flex-direction: row; /* NEW, Spec - Opera 12.1, Firefox 20+ */

    // -webkit-box-pack: justify; /* OLD - iOS 6-, Safari 3.1-6 */
    // -moz-box-pack: justify; /* OLD - Firefox 19- */
    // -webkit-justify-content: space-around; /* NEW - Chrome */
    // -ms-flex-pack: distribute; /* TWEENER - IE 10 */
    // justify-content: space-around;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
      padding-top: 50px;
      min-width: 200px;
      width: 30%;
      height: fit-content;
    }
  }
  .no-result-text {
    padding-top: 20px;
    text-align: center;
    color: #585858;
    font-size: 18px;
    font-weight: 500;
  }
  .no-result-text-bottom {
    padding-top: 5px;
    text-align: center;
    color: #585858;
    font-size: 13px;
    font-weight: 500;
    &.action {
      cursor: pointer;
      color: #ed1450;
      &:active {
        opacity: 0.7;
      }
    }
  }
}

.ant-modal-body {
  padding: 21px 24px 20px 24px;
}

.ant-modal-footer {
  .ant-btn {
    color: #ed1450;
    border: unset;
  }
}

.info-link{
  color: #ed1450;
  text-decoration: underline;
  cursor: pointer;
}